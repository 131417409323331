import * as ACTION from './types';
import { classesService } from '../../services/classes.service'

export function getCancellations(range, filters, page, orderBy, groupId) {
  return {
    types: [
      ACTION.LOADING_GET_CANCELLATIONS,
      ACTION.RECEIVE_GET_CANCELLATIONS,
      ACTION.ERROR_GET_CANCELLATIONS,
    ], 
    promise: classesService.getGroupCancellations(groupId, range, filters, page, orderBy)
  }
}

export function tableProps(name, page, sort) {
  return {
    type: ACTION.TABLEPROPS_GET_CANCELLATIONS,
    name,
    page,
    sort
  }
}

export function saveFilters(filters) {
  return { type: ACTION.SAVE_FILTERS, filters }
}

export function resetStateCancellations() {
  return { type: ACTION.RESET_STATE }
}
